

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main_questions_wrapper_container {
  .add_question_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
